import {Backdrop, Fade, Modal, withStyles} from '@material-ui/core'
import React, {PropsWithChildren} from 'react'

import {Icon} from 'layout/Icon'
import {Variant} from 'util/styler'
import css from './Popup.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type PopupType = {
	className?: string
	mod?: string
	open: boolean
	setOpen?: (t: boolean) => void
	onClose: () => void
}

const StyledBackdrop = withStyles({
	root: {
		background: 'rgba(76,77,76, 0.2)'
	}
})(Backdrop)

export const Popup: React.FC<PropsWithChildren<PopupType>> = ({
	className,
	mod,
	open,
	onClose,
	children
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			BackdropComponent={StyledBackdrop}
			BackdropProps={{
				timeout: 500
			}}
			className={styles.popup.with(className).toString()}
		>
			<Fade in={open} timeout={500}>
				<div className={styles.popup.wrapper.mod(mod)()}>{children}</div>
			</Fade>
		</Modal>
	)
}

export const PopupClose: React.FC<{
	className?: string
	onClose: () => void
}> = ({className, onClose}) => (
	<a className={styles.close.with(className)()} onClick={() => onClose()}>
		<Icon icon="close" />
	</a>
)

export const PopupContent: React.FC<
	PropsWithChildren<{
		className?: string
		mod?: Variant<'greylight' | 'large' | 'rounded'>
	}>
> = ({className, mod, children}) => (
	<div className={styles.content.with(className).mod(mod)()}>{children}</div>
)
