import {PropsWithChildren, createContext, useContext} from 'react'

import {CMSOkrareizenChannel} from 'cms-types'
import {GeneralData} from 'server/general'
import {LocaleContextProvider} from './locale/LocaleContext'

export type AppContextType = {
	general: GeneralData
	node: CMSOkrareizenChannel
}

const AppContext = createContext<AppContextType | false>(false)

export const AppContextProvider: React.FC<
	PropsWithChildren<AppContextType>
> = ({children, ...props}) => {
	return (
		<AppContext.Provider value={props}>
			<LocaleContextProvider>{children}</LocaleContextProvider>
		</AppContext.Provider>
	)
}

export const useApp = () => {
	const result = useContext(AppContext)
	if (!result)
		throw new Error(
			'Trying to use the useApp hook, but no AppContext is defined.'
		)
	return result
}
