import {Dispatch, FormEvent, SetStateAction, useState} from 'react'

import {CMSWysiwyg} from 'cms-types'
import {Checkbox} from 'layout/checkbox/Checkbox'
import {GenericStyles} from 'util/styler/ModuleStyles'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Newsletter.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export type Newsletter = {
	agreement: CMSWysiwyg
	btn: string
	placeholder: string
	thanks: string
	title: string
}

export const subscribe = async (
	event: FormEvent<HTMLFormElement>,
	isSubmitting: boolean,
	setEmail: Dispatch<SetStateAction<string>>,
	setErrorMessage: Dispatch<SetStateAction<string>>,
	setIsSubmitting: Dispatch<SetStateAction<boolean>>,
	setStep: Dispatch<SetStateAction<number>>
) => {
	event.preventDefault()
	if (!isSubmitting) {
		setIsSubmitting(true)
		setErrorMessage(null)

		const target = event.target as typeof event.target & {
			email: {value: string}
			firstname: {value: string}
			lastname: {value: string}
		}

		const res = await fetch('/api/newsletter/subscribe', {
			body: JSON.stringify({
				email: target.email.value,
				firstname: target.firstname.value,
				lastname: target.lastname.value
			}),
			headers: {
				'Content-Type': 'application/json'
			},
			method: 'POST'
		}).then((res) => {
			setIsSubmitting(false)
			return res
		})

		if (res.status === 200) {
			setEmail(target.email.value)
			setStep(2)
		} else {
			// an error occured
			const result = await res.json()

			if (result) {
				if (result.message) setErrorMessage(result.message)
				else if (result.error) setErrorMessage(result.error)
			}
		}
	}
}

export const addInterests = async (
	event: FormEvent<HTMLFormElement>,
	isSubmitting: boolean,
	setErrorMessage: Dispatch<SetStateAction<string>>,
	setIsSubmitting: Dispatch<SetStateAction<boolean>>,
	setStep: Dispatch<SetStateAction<number>>,
	onSuccessHandler?: () => void
) => {
	event.preventDefault()
	if (!isSubmitting) {
		setIsSubmitting(true)
		setErrorMessage(null)

		const target = event.target as typeof event.target & {
			email: {value: string}
			cityTrips: {checked: boolean}
			cruises: {checked: boolean}
			culturalTrips: {checked: boolean}
			danceHolidays: {checked: boolean}
			bicycleHolidays: {checked: boolean}
			motorHolidays: {checked: boolean}
			travelAround: {checked: boolean}
			skiHolidays: {checked: boolean}
			stayHolidays: {checked: boolean}
			walkingHolidays: {checked: boolean}
			meaningHolidays: {checked: boolean}
		}

		if (
			target.cityTrips.checked ||
			target.cruises.checked ||
			target.culturalTrips.checked ||
			target.danceHolidays.checked ||
			target.bicycleHolidays.checked ||
			target.motorHolidays.checked ||
			target.travelAround.checked ||
			target.skiHolidays.checked ||
			target.stayHolidays.checked ||
			target.walkingHolidays.checked ||
			target.meaningHolidays.checked
		) {
			const res = await fetch('/api/newsletter/interests', {
				body: JSON.stringify({
					email: target.email.value,
					cityTrips: target.cityTrips.checked,
					cruises: target.cruises.checked,
					culturalTrips: target.culturalTrips.checked,
					danceHolidays: target.danceHolidays.checked,
					bicycleHolidays: target.bicycleHolidays.checked,
					motorHolidays: target.motorHolidays.checked,
					travelAround: target.travelAround.checked,
					skiHolidays: target.skiHolidays.checked,
					stayHolidays: target.stayHolidays.checked,
					walkingHolidays: target.walkingHolidays.checked,
					meaningHolidays: target.meaningHolidays.checked
				}),
				headers: {
					'Content-Type': 'application/json'
				},
				method: 'POST'
			}).then((res) => {
				setIsSubmitting(false)
				if (onSuccessHandler) onSuccessHandler()
				return res
			})

			if (res.status === 200) {
				setStep(3)
			} else {
				// an error occured
				const result = await res.json()

				if (result) {
					if (result.message) setErrorMessage(result.message)
					else if (result.error) setErrorMessage(result.error)
				}
			}
		} else {
			setStep(3)
		}
	}
}

export const Newsletter: React.FC<Newsletter> = ({
	agreement,
	btn,
	placeholder,
	thanks,
	title
}) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [email, setEmail] = useState<string>(null)
	const [errorMessage, setErrorMessage] = useState<string>(null)
	const [step, setStep] = useState<number>(1)

	return (
		<div className={styles.newsletter()} id="newsletter">
			<h3 className={styles.newsletter.title()}>{title}</h3>
			<form
				onSubmit={
					email === null
						? (event: FormEvent<HTMLFormElement>) =>
								subscribe(
									event,
									isSubmitting,
									setEmail,
									setErrorMessage,
									setIsSubmitting,
									setStep
								)
						: (event: FormEvent<HTMLFormElement>) =>
								addInterests(
									event,
									isSubmitting,
									setErrorMessage,
									setIsSubmitting,
									setStep
								)
				}
				className={styles.newsletter.form()}
			>
				{step === 1 && (
					<FormStep1
						btn={btn}
						placeholder={placeholder}
						errorMessage={errorMessage}
						setErrorMessage={setErrorMessage}
						styles={styles}
					/>
				)}
				{step === 2 && (
					<FormStep2
						email={email}
						errorMessage={errorMessage}
						styles={styles}
					/>
				)}
				{step === 3 && <FormThanks text={thanks} />}
			</form>
			<div className={styles.newsletter.agreement()}>
				<Wysiwyg>{agreement}</Wysiwyg>
			</div>
		</div>
	)
}

export const FormStep1: React.FC<{
	btn: string
	errorMessage: string
	placeholder: string
	setErrorMessage: Dispatch<SetStateAction<string>>
	styles: GenericStyles
}> = ({btn, errorMessage, placeholder, setErrorMessage, styles}) => {
	return (
		<div className={styles.formstep1()}>
			<div className={styles.formstep1.top()}>
				<input
					type="text"
					name="firstname"
					placeholder={'Voornaam'}
					required={true}
					className={styles.formstep1.fieldArea.textField()}
				/>
				<input
					type="text"
					name="lastname"
					placeholder={'Achternaam'}
					required={true}
					className={styles.formstep1.fieldArea.textField()}
				/>
			</div>
			<div className={styles.formstep1.bottom()}>
				<div className={styles.formstep1.fieldArea()}>
					<input
						type="email"
						name="email"
						placeholder={placeholder}
						required={true}
						className={styles.formstep1.fieldArea.textField.is({
							error: errorMessage && errorMessage.length > 1
						})()}
						onChange={() => {
							setErrorMessage(null)
						}}
					/>
					{errorMessage && (
						<div className={styles.formstep1.fieldArea.error()}>
							{errorMessage}
						</div>
					)}
				</div>
				<input
					type="submit"
					value={btn}
					className={styles.formstep1.submit()}
				/>
			</div>
		</div>
	)
}

export const FormStep2: React.FC<{
	email: string
	errorMessage: string
	styles: GenericStyles
}> = ({email, errorMessage, styles}) => {
	const [cityTrips, setCityTrips] = useState<boolean>(false)
	const [cruises, setCruises] = useState<boolean>(false)
	const [culturalTrips, setCulturalTrips] = useState<boolean>(false)
	const [danceHolidays, setDanceHolidays] = useState<boolean>(false)
	const [bicycleHolidays, setBicycleHolidays] = useState<boolean>(false)
	const [motorHolidays, setMotorHolidays] = useState<boolean>(false)
	const [travelAround, setTravelAround] = useState<boolean>(false)
	const [skiHolidays, setSkiHolidays] = useState<boolean>(false)
	const [stayHolidays, setStayHolidays] = useState<boolean>(false)
	const [walkingHolidays, setWalkingHolidays] = useState<boolean>(false)
	const [meaningHolidays, setMeaningHolidays] = useState<boolean>(false)

	return (
		<div className={styles.formstep2()}>
			<h4 className={styles.formstep2.title()}>
				Welke vakanties interesseren je?
			</h4>
			<input type="hidden" name="email" value={email} />
			<ul className={styles.formstep2.interests()}>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Citytrips"
						isSelected={cityTrips}
						onClick={() => setCityTrips(!cityTrips)}
						name="cityTrips"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Cruises"
						isSelected={cruises}
						onClick={() => setCruises(!cruises)}
						name="cruises"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Culturele reizen"
						isSelected={culturalTrips}
						onClick={() => setCulturalTrips(!culturalTrips)}
						name="culturalTrips"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Dansvakanties"
						isSelected={danceHolidays}
						onClick={() => setDanceHolidays(!danceHolidays)}
						name="danceHolidays"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Fietsvakanties"
						isSelected={bicycleHolidays}
						onClick={() => setBicycleHolidays(!bicycleHolidays)}
						name="bicycleHolidays"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Motorvakanties"
						isSelected={motorHolidays}
						onClick={() => setMotorHolidays(!motorHolidays)}
						name="motorHolidays"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Rondreizen"
						isSelected={travelAround}
						onClick={() => setTravelAround(!travelAround)}
						name="travelAround"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Skivakanties"
						isSelected={skiHolidays}
						onClick={() => setSkiHolidays(!skiHolidays)}
						name="skiHolidays"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Verblijfsvakanties"
						isSelected={stayHolidays}
						onClick={() => setStayHolidays(!stayHolidays)}
						name="stayHolidays"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Wandelvakanties"
						isSelected={walkingHolidays}
						onClick={() => setWalkingHolidays(!walkingHolidays)}
						name="walkingHolidays"
					/>
				</li>
				<li className={styles.formstep2.interests.interest()}>
					<Checkbox
						label="Zingevingsvakanties"
						isSelected={meaningHolidays}
						onClick={() => setMeaningHolidays(!meaningHolidays)}
						name="meaningHolidays"
					/>
				</li>
			</ul>
			{errorMessage && (
				<div className={styles.formstep2.row()}>
					<div className={styles.formstep2.row.error()}>{errorMessage}</div>
				</div>
			)}
			<div className={styles.formstep2.row()}>
				<input
					type="submit"
					value={'Sla mijn voorkeuren op'}
					className={styles.formstep2.row.submit()}
				/>
			</div>
		</div>
	)
}

const FormThanks: React.FC<{text: string}> = ({text}) => {
	return (
		<div className={styles.formThanks()}>
			<p>{text}</p>
		</div>
	)
}
