import type {CMSEntryLink, CMSLink, CMSWysiwyg} from 'cms-types'
import type {
	CookieCategoryKey,
	CookieCategoryState,
	CookiesCategoryConfig
} from './CookieCategoryModel'

import {CookieCategoryModel} from './CookieCategoryModel'
import {CookieServiceModel} from './CookieServiceModel'

export type CookiesSettingsConfig = {
	title: string
	banner: {
		text: string
	}
	settings: {
		title: string
		text: CMSWysiwyg
		necessary: CMSEntryLink
		link: CMSLink
	}
	categories: Array<CookiesCategoryConfig>
}

export type CookiesSettingsState = Record<
	CookieCategoryKey,
	CookieCategoryState
>

export class CookieSettingsModel {
	constructor(
		readonly config: CookiesSettingsConfig,
		readonly state: CookiesSettingsState
	) {}

	// @Memoize()
	get categories(): Array<CookieCategoryModel> {
		if (!this.config || !this.config.categories) return []
		return this.config.categories.map(
			(config) => new CookieCategoryModel(this, config)
		)
	}

	// @Memoize()
	get enabledCategories(): Array<CookieCategoryModel> {
		return this.categories.filter((category) => category.isFullyEnabled)
	}

	// @Memoize()
	get enabledNone(): boolean {
		return this.enabledCategories.length === 0
	}

	// @Memoize()
	get enabledAll(): boolean {
		return this.categories.length === this.enabledCategories.length
	}

	// @Memoize()
	get services(): Array<CookieServiceModel> {
		const result = []
		this.categories.forEach((category) => {
			category.services.forEach((service) => {
				result.push(service)
			})
		})
		return result
	}

	findServiceById(id: number): CookieServiceModel {
		return this.services.find((service) => service.config.id === id)
	}

	// ----- State changes ----- //
	update(newState: CookiesSettingsState): CookieSettingsModel {
		return new CookieSettingsModel(this.config, newState)
	}

	updateCategory(
		category: CookieCategoryModel,
		newState: CookieCategoryState
	): CookieSettingsModel {
		return this.update({
			...this.state,
			[category.key]: newState
		})
	}

	enableAll(): CookieSettingsModel {
		const newState: CookiesSettingsState = {}
		this.categories.forEach((category) => {
			newState[category.key] = true
		})
		return this.update(newState)
	}

	// ----- Storage interaction ----- //
	loadFromStorage(storage: Storage): CookieSettingsModel {
		const state: CookiesSettingsState = {}
		this.categories.forEach((category) => {
			const enabled = storage && storage.getItem(category.key) === 'enabled'
			if (enabled) {
				state[category.key] = true
			} else {
				const enabledServices = []
				category.services.forEach((service) => {
					const serviceEnabled =
						storage && storage.getItem(service.key) === 'enabled'
					if (serviceEnabled) enabledServices.push(service.key)
				})
				state[category.key] = enabledServices
			}
		})
		return this.update(state)
	}

	syncStorage(storage: Storage): void {
		this.categories.forEach((category) => {
			if (category.isFullyEnabled) {
				storage.setItem(category.key, 'enabled')
			} else {
				storage.removeItem(category.key)
			}

			category.services.forEach((service) => {
				if (service.enabled) {
					storage.setItem(service.key, 'enabled')
				} else {
					storage.removeItem(service.key)
				}
			})
		})
	}
}
