import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState
} from 'react'

import type {CMSOkrareizenChannel} from 'cms-types'
import {CookieSettingsModel} from './models/CookieSettingsModel'
import type {CookiesSettingsConfig} from './models/CookieSettingsModel'
import {Tracking} from './tracking/Tracking'
import {storage} from 'util/storage'

export type CookiesContextType = {
	settings: CookieSettingsModel
	updateSettings: (model: CookieSettingsModel) => void
	settingsOpen: boolean
	setSettingsOpen: (isOpen: boolean) => void
	bannerOpen: boolean
	closeBanner: () => void
}
const CookiesContext = createContext<CookiesContextType>(null)
export const useCookies = () => useContext(CookiesContext)

export const CookiesProvider: React.FC<
	PropsWithChildren<{
		config: CookiesSettingsConfig
		page: CMSOkrareizenChannel
	}>
> = ({config, children, page}) => {
	const [settings, storeSettings] = useState<CookieSettingsModel>(undefined)
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [bannerOpen, setBannerOpen] = useState(false)
	const updateSettings = (model: CookieSettingsModel) => {
		model.syncStorage(storage)
		storeSettings(model)
	}
	const closeBanner = () => {
		setBannerOpen(false)
		storage.setItem('cookies_state', 'processed')
	}

	useEffect(() => {
		storeSettings(new CookieSettingsModel(config, {}).loadFromStorage(storage))
		setBannerOpen(storage.getItem('cookies_state') !== 'processed')
	}, [config])

	return (
		<CookiesContext.Provider
			value={{
				settings,
				updateSettings,
				settingsOpen,
				setSettingsOpen,
				bannerOpen,
				closeBanner
			}}
		>
			{children}
			<Tracking page={page} />
		</CookiesContext.Provider>
	)
}
