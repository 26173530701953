var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ea7ad20a673d1b3c1b05e95b8a5e18d8cff5e883"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
	environment: process.env.ENV,
	authToken: process.env.SENTRY_AUTH_TOKEN,
	dsn:
		SENTRY_DSN ||
		'https://fb237df08cfd443aa05ad0d0fa2ad05d@o60758.ingest.sentry.io/6071041',
	tracesSampleRate: 0.5,
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		new Sentry.Replay({
			maskAllText: false,
			maskAllInputs: false,
			blockAllMedia: false
		})
	]
})
