import {Dispatch, SetStateAction} from 'react'

import {CMSLink} from 'cms-types'
import {useRouter} from 'next/router'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './HoverMenu.module.scss'

const styles = fromModule(css)

export const HoverMenu: React.FC<{
	links: Array<CMSLink>
	isVisible: boolean
	setIsVisible: Dispatch<SetStateAction<boolean>>
}> = ({links, isVisible, setIsVisible}) => {
	const router = useRouter()

	if (links.length < 1) return null

	return (
		<div
			className={styles.hovermenu.is({
				visible: isVisible
			})()}
			onMouseOut={() => setIsVisible(false)}
		>
			<div className={styles.hovermenu.container()}>
				<div className={styles.hovermenu.container.links()}>
					{links.map((child, index) => {
						const isActive = router?.asPath === child.url

						return (
							<Link
								key={`${child.url}${index}`}
								to={child.url}
								className={styles.hovermenu.container.links.link.is({
									active: isActive
								})()}
								onClick={() => setIsVisible(false)}
							>
								{child.title}
							</Link>
						)
					})}
				</div>
			</div>
		</div>
	)
}
