import 'react-responsive-modal/styles.css'

import React, {CSSProperties, useState} from 'react'

import {Container} from 'layout/Container'
import {Mobilemenu} from 'layout/mobilemenu/Mobilemenu'
import {Navlinks} from 'layout/Navlinks'
import {Modal as ReactModal} from 'react-responsive-modal'
import css from './Subnavigation.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Subnavigation: React.FC = () => {
	const {general} = useApp()
	const links = general?.subnavigation?.links

	return (
		<div className={styles.subnavigation()}>
			<Container>
				<div className={styles.subnavigation.row()}>
					<SubnavigationHamburger />
					<Navlinks links={links} styles={styles.subnavigation.row.items} />
				</div>
			</Container>
		</div>
	)
}

const overlayStyles: CSSProperties = {
	background: 'rgba(85, 138, 166, 0.95)',
	pointerEvents: 'none'
}

const modalStyles: CSSProperties = {
	margin: 0,
	padding: 0,
	background: 'none',
	width: '100%',
	boxShadow: 'none',
	WebkitOverflowScrolling: 'touch',
	overflow: 'scroll'
}

const closeButtonStyles: CSSProperties = {
	fill: 'white',
	position: 'fixed',
	pointerEvents: 'auto'
}

const SubnavigationHamburger: React.FC = () => {
	const [isOpen, setOpen] = useState<boolean>(false)

	return (
		<>
			<a onClick={() => setOpen(true)} className={styles.hamburger()}>
				<div className={styles.hamburger.bars()}>
					<span className={styles.hamburger.bars.bar()} />
					<span className={styles.hamburger.bars.bar()} />
					<span className={styles.hamburger.bars.bar()} />
				</div>
				<p className={styles.hamburger.text()}>Menu</p>
			</a>
			<ReactModal
				open={isOpen}
				onClose={() => setOpen(false)}
				center
				focusTrapped={false}
				styles={{
					overlay: overlayStyles,
					modal: modalStyles,
					closeButton: closeButtonStyles
				}}
			>
				<Mobilemenu onClose={() => setOpen(false)} />
			</ReactModal>
		</>
	)
}
