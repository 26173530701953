import React, {useEffect, useState} from 'react'

import {CMSOkrareizenChannel} from 'cms-types'
import {Link} from 'util/link'
import css from './AdminTools.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'
import {useRouter} from 'next/router'

const styles = fromModule(css)

export const useIsAdmin = () => {
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			setIsAdmin(true)
			return
		}

		try {
			if (!localStorage.admin_enabled) return
			if (!localStorage.admin_refreshed) return

			const validUntil =
				Number(localStorage.admin_refreshed) + 1000 * 60 * 60 * 24 * 14
			if (Date.now() > validUntil) return
			setIsAdmin(true)
		} catch (e) {}
	}, [])

	return isAdmin
}

export const AdminTools: React.FC<{node: CMSOkrareizenChannel}> = ({node}) => {
	const isAdmin = useIsAdmin()
	const isDesktop = useMediaQuery('(min-width: 1200px)')
	const router = useRouter()

	if (!isDesktop) return null
	if (!isAdmin) return null
	if (!node) return null
	if (router.isPreview) return null

	return (
		<div className={styles.tools()}>
			<Link
				to={`${process.env.NEXT_PUBLIC_ADMIN_URL}/project/${node.project_key}/${node.language}/entry/${node.id}`}
				target="_blank"
				className={styles.tools.button()}
				rel="noreferrer"
			>
				Edit
			</Link>
			<Link
				to={`/api/data${node.url}`}
				target="_blank"
				className={styles.tools.button()}
				rel="noreferrer"
			>
				JSON
			</Link>
		</div>
	)
}
