import React, {FunctionComponent, HTMLProps, createContext} from 'react'

type ThemeStore = Record<string, any>

const ThemeContext = createContext<ThemeStore>({})

const widget: (attrs: HTMLProps<HTMLElement>) => React.FC = (
	view: Record<string, React.FC<any>>
) => {
	const name = Object.keys(view)[0]
	const defaultView = Object.values(view)[0]
	const Theme = (attrs) => (
		<ThemeContext.Consumer>
			{(theme: ThemeStore) => {
				const {children, ...rest} = attrs
				const View = theme[name] || defaultView
				return <View {...rest}>{children} </View>
			}}
		</ThemeContext.Consumer>
	)
	return Theme
}

export const createTheme = <
	T extends {
		[key: string]: React.FC<any>
	}
>(
	views: T
): {[P in keyof T]: T[P]} & FunctionComponent<{
	extend: Partial<{[P in keyof T]: React.FC<any>}>
}> =>
	Object.assign(
		({children, extend}) => (
			<ThemeContext.Consumer>
				{(theme) => (
					<ThemeContext.Provider value={{...theme, ...extend}}>
						{children}
					</ThemeContext.Provider>
				)}
			</ThemeContext.Consumer>
		),
		Object.entries(views).reduce(
			(acc, [name, value]) => ({
				...acc,
				[name]: widget({[name]: value})
			}),
			{}
		)
	) as any
