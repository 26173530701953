import {FormControlLabel, Switch} from '@material-ui/core'
import {Popup, PopupClose, PopupContent} from 'layout/popup/Popup'
import React, {useState} from 'react'

import AnimateHeight from 'react-animate-height'
import {CookieCategoryModel} from '../models/CookieCategoryModel'
import {CookieSettingsModel} from '../models/CookieSettingsModel'
import {CookieTheme} from './CookieTheme'
import {Icon} from 'layout/Icon'
import css from './CookieSettings.module.scss'
import {fromModule} from 'util/styler/Styler'
import {slugify} from '../../util/slugify'
import {useCookies} from '../cookies'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const CookieSettings: React.FC<{
	open: boolean
	onClose: () => void
}> = ({open, onClose}) => {
	const {
		settings: initialSettings,
		setSettingsOpen,
		updateSettings,
		closeBanner
	} = useCookies()
	const [settings = initialSettings, localUpdateSetting] =
		useState(initialSettings)
	const t = useTranslation()

	const globalUpdateSettings = (hasUpdate) => {
		updateSettings(hasUpdate)
		localUpdateSetting(hasUpdate)
	}

	const closeWithoutSaving = () => {
		globalUpdateSettings(initialSettings)
		onClose()
	}

	const copy = settings?.config?.settings

	return (
		<Popup open={open} onClose={closeWithoutSaving}>
			<PopupContent>
				<PopupClose onClose={closeWithoutSaving} />
				{copy?.title && (
					<CookieTheme.H2 className={styles.cookiesettings.title()} mod="bold">
						{copy.title}
					</CookieTheme.H2>
				)}
				{copy?.text && (
					<div className={styles.cookiesettings.text()}>
						<CookieTheme.Wysiwyg>{copy.text}</CookieTheme.Wysiwyg>
					</div>
				)}
				{copy?.link?.url && (
					<CookieTheme.Link
						to={copy.link.url}
						onClick={() => {
							globalUpdateSettings(initialSettings)
							setSettingsOpen(false)
						}}
						className={styles.cookiesettings.link()}
					>
						{copy.link.description || copy.link.title}
					</CookieTheme.Link>
				)}
				{settings?.categories && settings.categories.length > 0 && (
					<div className={styles.cookiesettings.items()}>
						{settings.categories.map((category: CookieCategoryModel) => (
							<CookieSettingsCategory
								key={category.key}
								category={category}
								updateSettings={localUpdateSetting}
							/>
						))}
					</div>
				)}
				<div className={styles.cookiesettings.buttons()}>
					<CookieTheme.Buttons>
						<CookieTheme.Button
							onClick={() => {
								globalUpdateSettings(settings)
								closeBanner()
								setSettingsOpen(false)
							}}
							mod={['outline', 'rounded']}
						>
							{t.cookies.save}
						</CookieTheme.Button>
						<CookieTheme.Button
							onClick={() => {
								globalUpdateSettings(settings.enableAll())
								closeBanner()
								setSettingsOpen(false)
							}}
							mod={['outline', 'rounded']}
						>
							{t.cookies.accept_all}
						</CookieTheme.Button>
					</CookieTheme.Buttons>
				</div>
			</PopupContent>
		</Popup>
	)
}

const CookieSettingsCategory: React.FC<{
	category: CookieCategoryModel
	updateSettings: (settings: CookieSettingsModel) => void
}> = ({category, updateSettings}) => {
	const [hasDetails, setDetails] = useState(category.isPartiallyEnabled)

	const copy = category.config

	return (
		<div className={styles.cookiesettingscategory()}>
			<div className={styles.cookiesettingscategory.top()}>
				<FormControlLabel
					control={
						<Switch
							checked={category.isFullyEnabled}
							disabled={category.isDisabled}
							onChange={() => {
								updateSettings(category.toggle())
							}}
							name={category.key}
							inputProps={{'aria-label': category.config.title}}
						/>
					}
					label={category.config.title}
				/>
				{category.services.length > 0 && (
					<a
						onClick={() => setDetails(!hasDetails)}
						className={styles.cookiesettingscategory.top.details.is({
							open: hasDetails
						})()}
					>
						<span className={styles.cookiesettingscategory.top.details.icon()}>
							<Icon icon="chevron_down" />
						</span>
					</a>
				)}
			</div>
			{copy.text && (
				<div className={styles.cookiesettingscategory.description()}>
					<CookieTheme.Wysiwyg>{copy.text}</CookieTheme.Wysiwyg>
				</div>
			)}
			{category.services.length > 0 && (
				<AnimateHeight height={hasDetails ? 'auto' : 0}>
					<div className={styles.cookiesettingscategory.services()}>
						{category.services.map((service) => (
							<div
								key={service.key}
								className={styles.cookiesettingscategory.services.item()}
							>
								<FormControlLabel
									className={styles.cookiesettingscategory.services.item.title()}
									control={
										<Switch
											checked={service.enabled}
											onChange={() => {
												updateSettings(service.toggle())
											}}
											name={service.key}
											inputProps={{
												'aria-label': slugify(service.config.title)
											}}
										/>
									}
									label={service.config.title}
								/>

								<div
									className={styles.cookiesettingscategory.services.item.text()}
								>
									<CookieTheme.Wysiwyg>
										{service.config.text}
									</CookieTheme.Wysiwyg>
								</div>
							</div>
						))}
					</div>
				</AnimateHeight>
			)}
		</div>
	)
}
