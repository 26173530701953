import type {CookieServiceKey, CookiesServiceConfig} from './CookieServiceModel'

import type {CMSWysiwyg} from 'cms-types'
import {CookieServiceModel} from './CookieServiceModel'
import {CookieSettingsModel} from './CookieSettingsModel'

export type CookieCategoryKey = string

export type CookiesCategoryConfig = {
	id: number
	key: CookieCategoryKey
	title: string
	text: CMSWysiwyg
	path: string
	services: Array<CookiesServiceConfig>
}

export type CookieCategoryState = true | Array<CookieServiceKey>

export class CookieCategoryModel {
	constructor(
		readonly settings: CookieSettingsModel,
		readonly config: CookiesCategoryConfig
	) {}

	// @Memoize()
	get state(): CookieCategoryState {
		return this.settings.state[this.key]
	}

	// @Memoize()
	get key(): CookieCategoryKey {
		return `cookies_${this.config.key || this.config.id}`
	}

	// @Memoize()
	get services(): Array<CookieServiceModel> {
		if (!this.config.services) return []
		return this.config.services.map(
			(config) => new CookieServiceModel(this, config)
		)
	}

	// @Memoize()
	get enabledServices(): Array<CookieServiceModel> {
		return this.services.filter((service) => service.enabled)
	}

	// @Memoize()
	get isFullyEnabled(): boolean {
		if (this.settings.config.settings.necessary?.id === this.config.id)
			return true
		return this.state === true
	}

	// @Memoize()
	get isDisabled(): boolean {
		return this.settings.config.settings.necessary?.id === this.config.id
	}

	// @Memoize()
	get isPartiallyEnabled(): boolean {
		return this.state !== true && this.state.length > 0
	}

	// ----- State changes ----- //
	update(newState: CookieCategoryState): CookieSettingsModel {
		return this.settings.updateCategory(this, newState)
	}

	toggle(): CookieSettingsModel {
		if (this.isFullyEnabled) return this.update([])
		return this.update(true)
	}

	toggleService(service: CookieServiceModel): CookieSettingsModel {
		if (this.isFullyEnabled) {
			return this.update(
				this.services
					.filter((s) => s.config.id !== service.config.id)
					.map((s) => s.key)
			)
		}

		if (service.enabled) {
			return this.update(
				this.enabledServices
					.filter((s) => s.config.id !== service.config.id)
					.map((s) => s.key)
			)
		}

		const enabled = [...this.enabledServices.map((s) => s.key), service.key]
		return this.update(enabled)
	}
}
