import css from './Checkbox.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Checkbox: React.FC<{
	isSelected: boolean
	label: string
	name?: string
	onClick: () => void
	tabIndex?: number
}> = ({isSelected, label, name, onClick, tabIndex}) => (
	<div
		className={styles.checkbox.is({selected: isSelected})()}
		onClick={() => onClick()}
		onKeyPress={(e) =>
			e.code === 'Space' || e.code === 'Enter' ? onClick() : null
		}
		tabIndex={tabIndex || 0}
	>
		<span className={styles.checkbox.selector()} />
		<label className={styles.checkbox.label()}>{label}</label>
		<input
			type="checkbox"
			checked={isSelected}
			name={name || label}
			className={styles.checkbox.checkbox()}
			readOnly={true}
		/>
	</div>
)
