import {storageFactory} from 'storage-factory'

export const local = storageFactory(() => localStorage)
export const session = storageFactory(() => sessionStorage)
const hasLocal = (): boolean => {
	if (typeof window === 'undefined' || !('localStorage' in window)) return false
	try {
		let testKey = '__some_random_key_you_are_not_going_to_use__'
		localStorage.setItem(testKey, testKey)
		localStorage.removeItem(testKey)
		return true
	} catch (e) {
		return false
	}
}
export const storage: Storage = hasLocal() ? local : session
