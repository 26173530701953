import * as langs from './index'

import React, {PropsWithChildren, createContext} from 'react'

export type LangKey = keyof typeof langs

const LocaleContext = createContext<LangKey>('nl')

export const LocaleContextProvider: React.FC<PropsWithChildren> = ({
	children
}) => {
	return <LocaleContext.Provider value="nl">{children}</LocaleContext.Provider>
}

export const useTranslation = () => {
	const lang = 'nl'
	return langs[lang]
}
