import {Container} from 'layout/Container'
import {Link} from 'util/link'
import {Navlinks} from 'layout/Navlinks'
import React from 'react'
import css from './Mobilemenu.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Mobilemenu: React.FC<{onClose?: () => void}> = ({onClose}) => {
	const {general} = useApp()
	const nav = general?.navigation?.links
	const subnav = general?.subnavigation?.links

	return (
		<div className={styles.mobilemenu()}>
			<Container>
				<div className={styles.mobilemenu.links()}>
					<div className={styles.mobilemenu.links.item()}>
						<Link
							to="/"
							onClick={onClose}
							className={styles.mobilemenu.links.item.link()}
						>
							Home
						</Link>
					</div>
				</div>
				<Navlinks
					links={nav}
					styles={styles.mobilemenu.links}
					onClick={onClose}
					isMobile={true}
				/>
				<Navlinks
					links={subnav}
					styles={styles.mobilemenu.links}
					onClick={onClose}
				/>
			</Container>
		</div>
	)
}
