import type {CMSWysiwyg} from 'cms-types'
import {CookieCategoryModel} from './CookieCategoryModel'
import {CookieSettingsModel} from './CookieSettingsModel'

export type CookieServiceKey = string

export type Cookie = {
	name: string
	domain: string
	duration: string
	custom: {
		amount: string
		unit: 'day' | 'month' | 'year'
	}
	description: string
}

export type CookiesServiceConfig = {
	id: number
	key: string
	title: string
	text: CMSWysiwyg
	missing_consent: CMSWysiwyg
	missing_enable: string
	cookies: Array<Cookie>
}

export class CookieServiceModel {
	constructor(
		readonly category: CookieCategoryModel,
		readonly config: CookiesServiceConfig
	) {}

	// @Memoize()
	get enabled(): boolean {
		if (this.category.state === true) return true
		return new Set(this.category.state).has(this.key)
	}

	// @Memoize()
	get key(): CookieServiceKey {
		return `${this.category.key}_${this.config.key || this.config.id}`
	}

	// ----- State changes ----- //
	toggle(): CookieSettingsModel {
		return this.category.toggleService(this)
	}
}
