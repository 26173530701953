export const nl = {
	projectname: 'Okra reizen',
	header: {
		hamburger: 'Menu'
	},
	footer: {
		payments: 'Betaalmogelijkheden:'
	},
	destinations: {
		result: 'overeenkomstig resultaat',
		results: 'overeenkomstige resultaten',
		ascending: 'oplopend',
		descending: 'aflopend',
		orderby: {
			price: 'Orden Prijs',
			date: 'Orden Datum'
		},
		viewall: 'Bekijk alle reizen',
		loadmore: 'Meer resultaten laden'
	},
	holidaycard: {
		from: 'Vanaf',
		traveltime: 'Reisduur',
		day: 'dag',
		days: 'dagen',
		organisator: 'Organisator'
	},
	holiday: {
		volunteer: 'Ga op reis als vrijwilliger',
		review: {
			title: 'Vakantiescore',
			reviews: 'beoordelingen',
			text: 'Score op basis van bevraging vorig jaar'
		},
		price: {
			title: 'Op vakantie vanaf *',
			text: '<p>* prijs op basis van verblijf in een tweepersoonskamer</p>',
			button: 'Boek nu je vakantie'
		},
		date: 'Datum',
		hotel: {
			address: 'Adres',
			telephone: 'Telefoon/Fax',
			email: 'E-mailadres',
			practical: 'Praktische info',
			distance: 'Afstand vanuit Brussel',
			height: 'Hoogte',
			latitude: 'Lengtegraad',
			longitude: 'Breedtegraad',
			website: 'Website'
		},
		labels: {
			fullybooked: 'Volzet',
			departure_guarantee: 'Vertrekgarantie'
		}
	},
	cookies: {
		save: 'Opslaan',
		settings: 'Cookie-instellingen',
		accept_all: 'Alle cookies accepteren',
		table: {
			name: 'Naam',
			description: 'Doel',
			domain: 'Domein/host',
			duration: 'Duur',
			keep: 'Permanent',
			session: 'Browsersessie',
			day: 'dag',
			days: 'dagen',
			month: 'maand',
			months: 'maanden',
			year: 'jaar',
			years: 'jaar'
		}
	},
	error404: {
		title: '404 - Pagina niet gevonden',
		text: 'De pagina die je probeert te bereiken is niet beschikbaar of is onlangs verwijderd.',
		btn: 'Ga naar startpagina'
	}
}
