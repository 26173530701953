import React, {Fragment, PropsWithChildren} from 'react'

import {Container} from 'layout/Container'
import {Image} from 'layout/Image'
import {Link} from 'util/link'
import {Navlinks} from 'layout/Navlinks'
import {Newsletter} from 'layout/newsletter/Newsletter'
import NextImage from 'next/legacy/image'
import {Social} from 'layout/Social'
import {Wysiwyg} from 'layout/Wysiwyg'
import css from './Footer.module.scss'
import {fromModule} from 'util/styler/Styler'
import {omit} from 'util/omit'
import {useApp} from 'AppContext'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Footer: React.FC = () => {
	const {general} = useApp()
	const footer = general?.footer
	if (!footer) return null

	return (
		<footer className={styles.footer()}>
			{footer?.image?.src && (
				<>
					<div className={styles.footer.image()}>
						<Image {...footer.image} alt={footer.image?.alt} as="background" />
					</div>
					<span className={styles.footer.overlay()} />
				</>
			)}
			<FooterTop />
			<FooterMiddle />
			<FooterNewsletter />
			<FooterBottom />
		</footer>
	)
}

const FooterRow: React.FC<PropsWithChildren> = ({children}) => (
	<div className={styles.row()}>{children}</div>
)
const FooterCol: React.FC<PropsWithChildren<{mod?: 'widthauto'}>> = ({
	mod,
	children
}) => <div className={styles.col.mod(mod)()}>{children}</div>

const FooterTop: React.FC = () => {
	const {general} = useApp()
	const nav = general.footer?.nav
	const subnav = general.footer?.subnav

	return (
		<Container>
			<FooterRow>
				<FooterCol>
					<FooterAbout />
				</FooterCol>
				<FooterCol>
					<Navlinks links={nav} styles={styles.nav} />
				</FooterCol>
				<FooterCol>
					<Navlinks links={subnav} styles={styles.subnav} />
				</FooterCol>
			</FooterRow>
		</Container>
	)
}
const FooterMiddle: React.FC = () => (
	<Container>
		<FooterRow>
			<FooterCol mod="widthauto">
				<FooterSocial />
				<FooterCollaboration />
			</FooterCol>
			<FooterCol>
				<FooterPayments />
			</FooterCol>
		</FooterRow>
	</Container>
)

const FooterNewsletter: React.FC<{}> = () => {
	const {general} = useApp()

	return (
		<Container>
			<Newsletter {...general.newsletter} />
		</Container>
	)
}

const FooterAbout: React.FC = () => {
	const {general} = useApp()
	const text = general.footer?.text

	return (
		<div className={styles.about()}>
			<Link to="/" title="Okra" className={styles.about.logo()}>
				<NextImage
					priority
					src={'/logo-okra.svg'}
					alt="Logo Okra"
					width={150}
					height={155}
				/>
			</Link>
			{text && <Wysiwyg className={styles.about.text()}>{text}</Wysiwyg>}
		</div>
	)
}

const FooterSocial: React.FC = () => {
	const {general} = useApp()
	const {follow, items} = general.footer?.social

	return (
		<div className={styles.social()}>
			{follow && <p className={styles.social.text()}>{follow}</p>}
			<Social social={items} />
		</div>
	)
}

const FooterCollaboration: React.FC = () => {
	const {general} = useApp()
	const collaboration = general.footer?.collaboration
	if (
		!collaboration ||
		!collaboration.logos ||
		collaboration.logos.length === 0
	)
		return null

	return (
		<div className={styles.collaboration()}>
			{collaboration.text && (
				<p className={styles.collaboration.text()}>{collaboration.text}</p>
			)}
			<div className={styles.collaboration.logos()}>
				{collaboration.logos.map((logo, i) => {
					if (!logo || !logo.image) return null

					const image = (
						<NextImage
							{...(logo && logo.image
								? omit(logo.image, [
										'focus',
										'meta',
										'size',
										'alt',
										'width',
										'title'
								  ])
								: null)}
							alt={logo?.image?.alt || ''}
							className={styles.collaboration.logos.logo.img()}
							width={100}
							height={100}
							layout="responsive"
						/>
					)

					const to: string = logo?.link?.url
					if (!to) {
						return (
							<div key={i} className={styles.collaboration.logos.logo()}>
								{image}
							</div>
						)
					}

					return (
						<Link key={i} to={to} className={styles.collaboration.logos.logo()}>
							{image}
						</Link>
					)
				})}
			</div>
		</div>
	)
}

const getImageHeight = (paymentKey: string): number => {
	switch (paymentKey) {
		case 'bancontact':
			return 25
		case 'visa':
			return 12
		case 'american-express':
			return 36
		case 'mastercard':
			return 28
	}
	return null
}

const FooterPayments: React.FC = () => {
	const t = useTranslation().footer
	const payments = [
		{key: 'bancontact', title: 'Bancontact'},
		{key: 'visa', title: 'Visa'},
		{key: 'american-express', title: 'American Express'},
		{key: 'mastercard', title: 'Mastercard'}
	]

	return (
		<div className={styles.payments()}>
			<p className={styles.payments.text()}>{t.payments}</p>
			<div className={styles.payments.items()}>
				{payments.map((payment, i) => (
					<NextImage
						key={i}
						src={`/${payment.key}.svg`}
						alt={payment.title || ''}
						title={payment.title}
						className={styles.payments.items.item()}
						width={36}
						height={getImageHeight(payment.key)}
					/>
				))}
			</div>
		</div>
	)
}

const FooterBottom: React.FC = () => {
	const {general} = useApp()
	const footer = general.footer
	if (!footer) return null

	return (
		<div className={styles.bottom()}>
			<Container>
				<div className={styles.bottom.row()}>
					{footer?.copyright && (
						<p className={styles.bottom.row.copyright()}>{footer.copyright}</p>
					)}
					{footer?.links && footer.links.length > 0 && (
						<div className={styles.bottom.row.links()}>
							{footer.links.map((link, i) => (
								<Fragment key={i}>
									{i > 0 && (
										<span className={styles.bottom.row.links.divider()}>|</span>
									)}
									<Link
										to={link?.url}
										className={styles.bottom.row.links.link()}
									>
										{link.description || link.title}
									</Link>
								</Fragment>
							))}
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}
