import React, {PropsWithChildren} from 'react'

import css from './Container.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Container: React.FC<PropsWithChildren> = ({children}) => (
	<div className={styles.container()}>{children}</div>
)
