import 'styles/globals.scss'

import {CMSChannelMeta, CMSOkrareizenChannel} from 'cms-types'

import {AdminTools} from 'layout/admin/AdminTools'
import {AppContextProvider} from 'AppContext'
import type {AppProps} from 'next/app'
import {CookiesProvider} from 'cookies/cookies'
import {GeneralData} from 'server/general'
import Head from 'next/head'
import {Layout} from 'layout/Layout'
import TagManager from 'react-gtm-module'
import {ThemeProvider} from '@material-ui/styles'
import {createTheme} from '@material-ui/core/styles'
import {useEffect} from 'react'

function _app(props: AppProps) {
	useEffect(() => {
		if (!process.env.NEXT_PUBLIC_GTM_ID) return
		TagManager.initialize({gtmId: process.env.NEXT_PUBLIC_GTM_ID})
	}, [])

	const {Component, pageProps} = props
	const general: GeneralData = pageProps.general
	const node: CMSOkrareizenChannel = pageProps.node
	const meta: CMSChannelMeta = node?.metadata

	const MuiTheme = createTheme({
		typography: {
			fontFamily: 'inherit'
		},
		palette: {
			primary: {
				main: '#e95232'
			},
			secondary: {
				main: '#558aa6'
			}
		}
	})

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				{process.env.NODE_ENV === 'production' ? (
					<meta name="robots" content="index, follow" />
				) : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="language" content="nl" />
				<link rel="shortcut icon" href="/favicon.png" />
				{(meta || node) && (
					<>
						<title>
							{meta?.title && meta?.title !== node.title
								? meta.title
								: node.title + ' - ' + 'Okra reizen'}
						</title>
						<meta property="og:type" content="website" />
						<meta property="og:url" content={node.url} />
						<meta
							property="og:title"
							content={meta?.og?.title || meta?.title || node.title}
						/>
					</>
				)}
				{meta?.description && (
					<>
						<meta name="description" content={meta.description} />
						<meta
							property="og:description"
							content={meta.og?.description || meta.description}
						/>
					</>
				)}
				{meta?.image && (
					<>
						<meta property="og:image" content={meta.image.src} />
						<meta property="og:image:width" content={'' + meta.image.width} />
						<meta property="og:image:height" content={'' + meta.image.height} />
					</>
				)}
			</Head>
			<AppContextProvider general={general} node={node}>
				<CookiesProvider config={general?.cookies} page={node}>
					<ThemeProvider theme={MuiTheme}>
						<Layout>
							{node && <AdminTools node={node} />}
							{node && <Component {...node} />}
							{!node && <Component />}
						</Layout>
					</ThemeProvider>
				</CookiesProvider>
			</AppContextProvider>
		</>
	)
}

export default _app
