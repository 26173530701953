import NextImage, {ImageProps} from 'next/legacy/image'

import {CMSImage} from 'cms-types'
import css from './Image.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

type AsTypes = 'background' | 'ratio' | 'square'
type AsType = {
	as?: AsTypes
}
declare type ImgElementStyle = NonNullable<
	JSX.IntrinsicElements['img']['style']
>
type backgroundImageType = {
	layout: 'fill'
	objectFit: 'cover' | ImgElementStyle['objectFit']
	objectPosition: ImageProps['objectPosition']
	sizes?: string | undefined
}
type ratioImageType = {
	layout: 'responsive'
	width: ImageProps['width']
	height: ImageProps['height']
}
type squareImageType = {
	layout: 'responsive'
	width: ImageProps['width']
	height: ImageProps['height']
	objectFit: 'cover' | ImgElementStyle['objectFit']
	objectPosition: ImageProps['objectPosition']
}

export const Image: React.FC<
	AsType &
		CMSImage &
		ImageProps & {
			className?: string
			ratioWidth?: number
			sizes?: string | undefined
		}
> = ({as, className, ratioWidth, sizes, priority = false, ...image}) => {
	if (!image?.src) return null
	return (
		<div className={styles.image.mod(as).with(className)()}>
			<NextImage
				priority={priority}
				sizes={sizes}
				{...switchProps(image, as, ratioWidth)}
				alt={image?.alt || image?.title || ''}
				src={image.src}
			/>
		</div>
	)
}

export const getImageRatio = (image: CMSImage, width: number) => {
	if (image.height > image.width) return (image.height / image.width) * width
	return width / (image.width / image.height)
}

const switchProps = (
	image: CMSImage,
	as?: AsTypes,
	ratioWidth?: number
): backgroundImageType | ratioImageType | squareImageType | CMSImage => {
	const focus = image.focus
		? `${image.focus?.x * 100}% ${image.focus?.y * 100}%`
		: '50% 50%'

	switch (as) {
		case 'background':
			return {
				layout: 'fill',
				objectFit: 'cover',
				objectPosition: focus
			}

		case 'ratio':
			return {
				layout: 'responsive',
				width: (ratioWidth || 640) * 2,
				height: getImageRatio(image, ratioWidth || 640) * 2
			}

		case 'square':
			return {
				layout: 'responsive',
				width: 600 * 2,
				height: 600 * 2,
				objectFit: 'cover',
				objectPosition: focus
			}

		default:
			return {...image}
	}
}
