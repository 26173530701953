import {Button} from 'layout/Button'
import {Container} from 'layout/Container'
import {Link} from 'util/link'
import {Navlinks} from 'layout/Navlinks'
import NextImage from 'next/legacy/image'
import React from 'react'
import css from './Header.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const Header: React.FC = () => {
	return (
		<div className={styles.header()}>
			<Container>
				<div className={styles.header.row()}>
					<div>
						<HeaderLogo />
					</div>
					<HeaderNav />
				</div>
			</Container>
		</div>
	)
}

const HeaderLogo: React.FC = () => {
	const t = useTranslation()

	return (
		<Link to="/" title={t.projectname} className={styles.logo()}>
			<NextImage
				priority={true}
				src={'/logo-okra.svg'}
				alt="Logo Okra"
				width={150}
				height={155}
			/>
		</Link>
	)
}

const HeaderNav: React.FC = () => {
	const {general} = useApp()
	const links = general?.navigation?.links
	const button = general?.navigation?.button

	return (
		<div className={styles.nav()}>
			<Navlinks links={links} styles={styles.nav.items} />
			{button?.url && (
				<Button to={button?.url} className={styles.nav.button()} mod="green">
					{button.description || button.title}
				</Button>
			)}
		</div>
	)
}
