import React from 'react'
import {Trusted} from 'util/trusted'
import {Variant} from 'util/styler'
import css from './Wysiwyg.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const Wysiwyg: React.FC<{
	className?: string
	mod?: Variant<'inherit' | 'green'>
	listStyle?: 'checkmarks' | 'pentagons'
	children: any
}> = ({className, mod, listStyle, children}) => {
	return (
		<div
			className={styles.wysiwyg
				.with('wysiwyg')
				.with(className)
				.mod(mod)
				.mod(listStyle)()}
		>
			<Trusted>{children}</Trusted>
		</div>
	)
}
