import {CookieSettings} from './CookieSettings'
import {CookieTheme} from './CookieTheme'
import React from 'react'
import css from './CookieBanner.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useCookies} from '../cookies'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export const CookieBanner: React.FC = () => {
	const t = useTranslation()
	const {
		settings,
		updateSettings,
		settingsOpen,
		setSettingsOpen,
		bannerOpen,
		closeBanner
	} = useCookies()
	const copy = settings?.config?.banner

	return (
		<div className={styles.cookiebanner.is({hidden: !bannerOpen})()}>
			<CookieTheme.Container>
				<div className={styles.cookiebanner.row()}>
					<div className={styles.cookiebanner.content()}>
						{copy?.text && (
							<CookieTheme.Wysiwyg>{copy.text}</CookieTheme.Wysiwyg>
						)}
					</div>
					<CookieTheme.Buttons className={styles.cookiebanner.buttons()}>
						<CookieTheme.Button
							onClick={() => setSettingsOpen(!settingsOpen)}
							mod={['outline', 'rounded', 'small', 'white']}
						>
							{t.cookies.settings}
						</CookieTheme.Button>
						<CookieTheme.Button
							onClick={() => {
								updateSettings(settings.enableAll())
								closeBanner()
							}}
							mod={['outline', 'rounded', 'small', 'white']}
						>
							{t.cookies.accept_all}
						</CookieTheme.Button>
					</CookieTheme.Buttons>
				</div>
			</CookieTheme.Container>
			<CookieSettings
				open={settingsOpen}
				onClose={() => setSettingsOpen(false)}
			/>
		</div>
	)
}
