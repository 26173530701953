export * as arrow_down from './arrow_down.svg'
export * as arrow_left from './arrow_left.svg'
export * as bus from './bus.svg'
export * as car from './car.svg'
export * as chevron_down from './chevron_down.svg'
export * as chevron_left from './chevron_left.svg'
export * as chevron_right from './chevron_right.svg'
export * as chevron_slider_right from './chevron_slider_right.svg'
export * as close from './close.svg'
export * as culture from './culture.svg'
export * as cycling from './cycling.svg'
export * as dancing from './dancing.svg'
export * as facebook from './facebook.svg'
export * as instagram from './instagram.svg'
export * as linkedin from './linkedin.svg'
export * as modal_close from './modal_close.svg'
export * as motor from './motor.svg'
export * as plane from './plane.svg'
export * as residence_holidays from './residence_holidays.svg'
export * as sort_down from './sort_down.svg'
export * as sort_up from './sort_up.svg'
export * as travel from './travel.svg'
export * as twitter from './twitter.svg'
export * as walking from './walking.svg'
export * as youtube from './youtube.svg'
