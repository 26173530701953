import React, {PropsWithChildren} from 'react'

import {CookieBanner} from 'cookies/views/CookieBanner'
import {Footer} from './footer/Footer'
import {Header} from './header/Header'
import {Subnavigation} from './subnavigation/Subnavigation'
import {styler} from 'util/styler/Styler'

const styles = styler('block')

export const Layout: React.FC<PropsWithChildren> = ({children}) => {
	return (
		<div className={styles()}>
			<Subnavigation />
			<Header />
			{children}
			<Footer />
			<CookieBanner />
		</div>
	)
}
