import React, {HTMLProps, PropsWithChildren} from 'react'

import {Trusted} from 'util/trusted'
import {Link as UtilLink} from '../../util/link'
import {Variant} from 'util/styler'
import {createTheme} from 'util/createtheme'
import css from './CookieTheme.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

type Div = HTMLProps<HTMLDivElement>

const Container: React.FC<Div> = ({children, ...props}) => {
	return (
		<div {...props} className={styles.cookietheme.container()}>
			{children}
		</div>
	)
}

type Heading = React.FC<
	HTMLProps<HTMLHeadingElement> & {as?: any; mod?: Variant<HeadingMods>}
>

export type HeadingMods =
	| 'bold'
	| 'green'
	| 'white'
	| 'marked'
	| 'slantleft'
	| 'slantright'

const H1: Heading = ({as: Tag = 'h1', mod, ...props}) => (
	<div className={props.className}>
		<Tag {...props} className={styles.cookietheme.h1.mod(mod)()} />
	</div>
)
const H2: Heading = ({as: Tag = 'h2', mod, ...props}) => (
	<div className={props.className}>
		<Tag {...props} className={styles.cookietheme.h2.mod(mod)()} />
	</div>
)
const H3: Heading = ({as: Tag = 'h3', mod, ...props}) => (
	<div className={props.className}>
		<Tag {...props} className={styles.cookietheme.h3.mod(mod)()} />
	</div>
)
const H4: Heading = ({as: Tag = 'h4', mod, ...props}) => (
	<div className={props.className}>
		<Tag {...props} className={styles.cookietheme.h4.mod(mod)()} />
	</div>
)
const H5: Heading = ({as: Tag = 'h5', mod, ...props}) => (
	<div className={props.className}>
		<Tag {...props} className={styles.cookietheme.h5.mod(mod)()} />
	</div>
)

const P: React.FC<HTMLProps<HTMLParagraphElement>> = (props) => (
	<p {...props} className={styles.cookietheme.p.mergeProps(props)()} />
)

const Wysiwyg: React.FC<{children: string} & any> = (props) => (
	<Trusted
		{...props}
		className={styles.cookietheme.wysiwyg.mergeProps(props)()}
	/>
)

const Buttons: React.FC<PropsWithChildren<{className?: string}>> = ({
	className,
	children
}) => (
	<div className={styles.cookietheme.buttons.with(className)()}>{children}</div>
)

const Button: React.FC<
	any & {
		className?: string
		mod?: Variant<'outline' | 'white' | 'rounded' | 'small' | 'blue'>
	}
> = ({className, mod, children, ...props}) => {
	return (
		<UtilLink
			{...props}
			className={styles.cookietheme.button.with(className).mod(mod)()}
		>
			{children}
		</UtilLink>
	)
}

const Link: React.FC<any> = ({children, ...props}) => {
	return (
		<UtilLink
			{...props}
			className={styles.cookietheme.link.mergeProps(props)()}
		>
			{children}
		</UtilLink>
	)
}

export const cookieTheme = {
	Container,
	H1,
	H2,
	H3,
	H4,
	H5,
	P,
	Wysiwyg,
	Buttons,
	Button,
	Link
}

export const CookieTheme = createTheme(cookieTheme)
