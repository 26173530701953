import {CMSLink} from 'cms-types'
import {GenericStyler} from 'util/styler/ModuleStyles'
import {HoverMenu} from './hovermenu/HoverMenu'
import {Link} from 'util/link'
import {useRouter} from 'next/router'
import {useState} from 'react'

const NavlinksLink: React.FC<{
	isMobile: boolean
	linkObj: {
		link: CMSLink
		children: Array<CMSLink>
	}
	onClick: () => void
	styles: GenericStyler
}> = ({isMobile, linkObj, onClick, styles}) => {
	const router = useRouter()
	const isActive =
		router?.asPath === linkObj.link.url ||
		router?.asPath.includes(linkObj.link.url)
	const [isVisible, setIsVisible] = useState<boolean>(false)
	return (
		<div
			className={styles.item()}
			onMouseOver={() => setIsVisible(true)}
			onMouseOut={() => setIsVisible(false)}
		>
			<Link
				to={linkObj.link.url}
				onClick={onClick}
				className={styles.item.link.is({
					active: isActive
				})()}
			>
				{linkObj.link.description || linkObj.link.title}
			</Link>
			{!isMobile && linkObj.children && linkObj.children.length > 0 && (
				<HoverMenu
					links={linkObj.children}
					isVisible={isVisible}
					setIsVisible={setIsVisible}
				/>
			)}
			{isMobile &&
				linkObj.children &&
				linkObj.children.length > 0 &&
				linkObj.children.map((linkObjChild, index) => (
					<div key={index} className={styles.item.subitem()}>
						<Link
							to={linkObjChild.url}
							onClick={onClick}
							className={styles.item.link.is({
								active:
									router?.asPath === linkObjChild.url ||
									router?.asPath.includes(linkObjChild.url)
							})()}
						>
							{linkObjChild.description || linkObjChild.title}
						</Link>
					</div>
				))}
		</div>
	)
}

export const Navlinks: React.FC<{
	isMobile?: boolean
	links: Array<{
		link: CMSLink
		children: Array<CMSLink>
	}>
	styles: GenericStyler
	onClick?: () => void
}> = ({isMobile, links, styles, onClick}) => {
	if (!links || links.length === 0) return null

	return (
		<nav className={styles()}>
			{links?.map((linkObj, index) => (
				<NavlinksLink
					key={`${linkObj.link.url}${index}`}
					isMobile={isMobile}
					linkObj={linkObj}
					onClick={onClick}
					styles={styles}
				/>
			))}
		</nav>
	)
}
