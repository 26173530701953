import {useEffect, useRef} from 'react'

import TagManager from 'react-gtm-module'
import {useCookies} from '../cookies'

export const useGTMTracking = (url: string, title: string) => {
	const initial = useRef(true)
	const {settings} = useCookies()

	// We also trigger a pageview if the cookie preferences change (eg. someone selected 'accept all')
	useEffect(() => {
		const id: number = window['__GTM_TRACKING__'] || null
		if (!id) return
		if (initial.current) {
			TagManager.initialize({gtmId: id})
			initial.current = false
		}

		TagManager.dataLayer({
			dataLayer: {
				event: 'VirtualPageview',
				virtualPageURL: url,
				virtualPageTitle: title,
				anonymize_ip: true
			}
		})
	}, [url, settings, title])
}
